exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-campaign-page-tsx": () => import("./../../../src/templates/campaign-page.tsx" /* webpackChunkName: "component---src-templates-campaign-page-tsx" */),
  "component---src-templates-clothing-service-segment-tsx": () => import("./../../../src/templates/clothing-service-segment.tsx" /* webpackChunkName: "component---src-templates-clothing-service-segment-tsx" */),
  "component---src-templates-company-tsx": () => import("./../../../src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-conversion-brochures-download-index-tsx": () => import("./../../../src/templates/conversion/brochures-download/index.tsx" /* webpackChunkName: "component---src-templates-conversion-brochures-download-index-tsx" */),
  "component---src-templates-conversion-contact-index-tsx": () => import("./../../../src/templates/conversion/contact/index.tsx" /* webpackChunkName: "component---src-templates-conversion-contact-index-tsx" */),
  "component---src-templates-conversion-service-test-index-tsx": () => import("./../../../src/templates/conversion/service-test/index.tsx" /* webpackChunkName: "component---src-templates-conversion-service-test-index-tsx" */),
  "component---src-templates-full-service-full-service-tsx": () => import("./../../../src/templates/full-service/full-service.tsx" /* webpackChunkName: "component---src-templates-full-service-full-service-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-legal-markdown-page-tsx": () => import("./../../../src/templates/legal-markdown-page.tsx" /* webpackChunkName: "component---src-templates-legal-markdown-page-tsx" */),
  "component---src-templates-non-clothing-service-segment-tsx": () => import("./../../../src/templates/non-clothing-service-segment.tsx" /* webpackChunkName: "component---src-templates-non-clothing-service-segment-tsx" */),
  "component---src-templates-press-detail-tsx": () => import("./../../../src/templates/press/detail.tsx" /* webpackChunkName: "component---src-templates-press-detail-tsx" */),
  "component---src-templates-press-overview-tsx": () => import("./../../../src/templates/press/overview.tsx" /* webpackChunkName: "component---src-templates-press-overview-tsx" */),
  "component---src-templates-product-collection-tsx": () => import("./../../../src/templates/product-collection.tsx" /* webpackChunkName: "component---src-templates-product-collection-tsx" */),
  "component---src-templates-product-details-index-tsx": () => import("./../../../src/templates/product-details/index.tsx" /* webpackChunkName: "component---src-templates-product-details-index-tsx" */),
  "component---src-templates-product-industry-clothing-tsx": () => import("./../../../src/templates/product-industry-clothing.tsx" /* webpackChunkName: "component---src-templates-product-industry-clothing-tsx" */),
  "component---src-templates-product-industry-non-clothing-tsx": () => import("./../../../src/templates/product-industry-non-clothing.tsx" /* webpackChunkName: "component---src-templates-product-industry-non-clothing-tsx" */),
  "component---src-templates-product-overview-tsx": () => import("./../../../src/templates/product-overview.tsx" /* webpackChunkName: "component---src-templates-product-overview-tsx" */),
  "component---src-templates-search-search-result-page-tsx": () => import("./../../../src/templates/search/search-result-page.tsx" /* webpackChunkName: "component---src-templates-search-search-result-page-tsx" */),
  "component---src-templates-service-test-page-tsx": () => import("./../../../src/templates/service-test-page.tsx" /* webpackChunkName: "component---src-templates-service-test-page-tsx" */),
  "component---src-templates-sitemap-tsx": () => import("./../../../src/templates/sitemap.tsx" /* webpackChunkName: "component---src-templates-sitemap-tsx" */)
}

